/* Rumba Colors Variables */


:root {
	--rmb-success: #27af66;
	--rmb-success-light: #c1f1d7;
	--rmb-warning: #f1b736;
	--rmb-warning-light: #fceecf;
	--rmb-danger: #ff6b62;
	--rmb-danger-light: #ffcac7;
	--rmb-primary: #8b62ff;
	--rmb-primary-light: #d5c7ff;
	--rmb-secondary: #62a1ff;
	--rmb-secondary-light: #cce0ff;
	--rmb-default: #85858c;
	--rmb-default-light: #dde4e9;
	--rmb-hover: #f2f5f7;
}
body::-webkit-scrollbar {
	display: none;
}
.rmb-text-success {
	color: var(--rmb-success);
}
.rmb-text-warning {
	color: var(--rmb-warning);
}
.rmb-text-danger {
	color: var(--rmb-danger);
}
.rmb-text-primary {
	color: var(--rmb-primary);
}
.rmb-text-secondary {
	color: var(--rmb-secondary);
}
.rmb-text-default {
	color: var(--rmb-default);
}
.rmb-text-success-light {
	color: var(--rmb-success-light);
}
.rmb-text-warning-light {
	color: var(--rmb-warning-light);
}
.rmb-text-danger-light {
	color: var(--rmb-danger-light);
}
.rmb-text-primary-light {
	color: var(--rmb-primary-light);
}
.rmb-text-secondary-light {
	color: var(--rmb-secondary-light);
}
.rmb-text-default-light {
	color: var(--rmb-default-light);
}
.rmb-bg-success {
	background-color: var(--rmb-success);
}
.rmb-bg-warning {
	background-color: var(--rmb-warning);
}
.rmb-bg-danger {
	background-color: var(--rmb-danger);
}
.rmb-bg-primary {
	background-color: var(--rmb-primary);
}
.rmb-bg-secondary {
	background-color: var(--rmb-secondary);
}
.rmb-bg-default {
	background-color: var(--rmb-default);
}
.rmb-bg-success-light {
	background-color: var(--rmb-success-light);
}
.rmb-bg-warning-light {
	background-color: var(--rmb-warning-light);
}
.rmb-bg-danger-light {
	background-color: var(--rmb-danger-light);
}
.rmb-bg-primary-light {
	background-color: var(--rmb-primary-light);
}
.rmb-bg-secondary-light {
	background-color: var(--rmb-secondary-light);
}
.rmb-bg-default-light {
	background-color: var(--rmb-default-light);
}
.rmb-border-success {
	border: 1px solid var(--rmb-success);
}
.rmb-border-warning {
	border: 1px solid var(--rmb-warning);
}
.rmb-border-danger {
	border: 1px solid var(--rmb-danger);
}
.rmb-border-primary {
	border: 1px solid var(--rmb-primary);
}
.rmb-border-secondary {
	border: 1px solid var(--rmb-secondary);
}
.rmb-border-default {
	border: 1px solid var(--rmb-default);
}
.rmb-border-success-light {
	border: 1px solid var(--rmb-success-light);
}
.rmb-border-warning-light {
	border: 1px solid var(--rmb-warning-light);
}
.rmb-border-danger-light {
	border: 1px solid var(--rmb-danger-light);
}
.rmb-border-primary-light {
	border: 1px solid var(--rmb-primary-light);
}
.rmb-border-secondary-light {
	border: 1px solid var(--rmb-secondary-light);
}
.rmb-border-default-light {
	border: 1px solid var(--rmb-default-light);
}

body {
	background-color: var(--rmb-hover);
}
.rumba-header.fixed-header {
	display: none !important;
	opacity: 0 !important;
}
.badge {
	padding: 3px 6px !important;
}

.rmb-btn-primary {
	background-color: var(--rmb-primary);
	border-color: var(--rmb-primary);
	color: #fff;
}

.rmb-btn-primary:hover {
	background-color: var(--rmb-primary-light);
	border-color: var(--rmb-primary-light);
	color: #fff;
}

.rmb-btn-secondary {
	background-color: var(--rmb-secondary);
	border-color: var(--rmb-secondary);
	color: #fff;
}

.rmb-btn-secondary:hover {
	background-color: var(--rmb-secondary-light);
	border-color: var(--rmb-secondary-light);
	color: #fff;
}

.body-wrapper > .container-fluid {
	max-width: 90%;
}

.alert-info {
	background-color: var(--rmb-secondary-light);
	border-color: var(--rmb-secondary);
	color: var(--rmb-secondary);
}

.alert-warning {
	background-color: var(--rmb-warning-light);
	border-color: var(--rmb-warning);
	color: var(--rmb-warning);
}

.alert-danger {
	background-color: var(--rmb-danger-light);
	border-color: var(--rmb-danger);
	color: var(--rmb-danger);
}

.alert-success {
	background-color: var(--rmb-success-light);
	border-color: var(--rmb-success);
	color: var(--rmb-success);
}

p.error {
	color: red;
}
/*iframe#webpack-dev-server-client-overlay{display:none!important}*/

.user-image,
.user-image .rounded-circle,
.user-image img {
	width: 150px;
	height: 150px;
}
.product-icon-image,
.product-icon-image .rounded-circle,
.product-icon-image img {
	width: 200px;
	height: auto;
}

.product-icon-image img {
	object-fit: cover;
	max-height: 150px;
}

.icon-image {
	height: 60px;
	object-fit: cover;
}

.booking-form {
	border-right: 1px solid #f3f3f3;
}
.booking-group:not(:last-child) {
	border-bottom: 2px solid #f0f0f0;
	padding-bottom: 30px;
}
.form-group h6 {
	font-size: 0.8rem !important;
	text-transform: uppercase !important;
}
.card-body .bPokRt:hover,
.card-body .rdt_TableRow:hover,
.form-control:disabled {
	background-color: var(--rmb-hover);
	cursor: pointer;
}
.pf-uid-href {
	display: flex;
	flex-wrap: nowrap;
	flex-shrink: 0;
}

.card-body .badge {
	font-weight: bold;
}
.card-body .bPokRt img {
	padding: 10px;
	max-height: 50px;
	width: auto;
}
.users-list .bPokRt img {
	padding: 10px;
	max-height: 70px;
	width: auto;
}
.logs-wrapper {
	color: #fff;
	background: #3f3f3f;
	padding: 20px;
	border-radius: 5px;
}
.model-name {
	text-transform: capitalize;
}
.img-bg {
	background-color: var(--bs-secondary-bg);
}
.app-header .navbar .navbar-nav.quick-links .nav-item:hover .nav-link:before,
.nav-icon-hover:hover::before {
	background-color: #ffffff;
	color: #000;
}
.app-header .navbar .navbar-nav .nav-item .nav-link {
	color: #000;
}
.icon-eye {
	position: relative;
	bottom: 8px;
}

.payment-booking-id {
	width: 60%;
}
.flex-dir-col {
	flex-direction: column;
}
.lst-rw-form > div {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
	width: 100%;
}
.lst-rw-form textarea {
	resize: none;
	overflow: none;
}
ul.filters {
	margin: 0;
	padding: 0;
	list-style: none;
}
ul.filters .active {
	color: #000000;
	font-weight: bold;
}
ul.filters > li {
	display: inline-block;
	position: relative;
}
ul.filters > li button {
	cursor: pointer;
	border: none;
	background: none;
	padding: 10px 20px;
	background-color: #f2f5f7;
	border-radius: 5px;
	margin-right: 10px;
}
ul.filters > li button:hover {
	background-color: #e6e6e6;
}

ul.filters > li ul {
	display: none;
	position: absolute;
	top: calc(100% - 4px);
	left: 0;
	background-color: #f2f5f7;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 20px;
	border-radius: 5px;
	z-index: 10;
	width: 200px;
}

ul.filters > li:hover ul {
	display: block;
}

ul.filters > li ul li {
	cursor: pointer;
	margin-bottom: 5px;
}

ul.filters > li ul li:hover {
	color: #000000;
}
.sidebar-nav ul .sidebar-item .sidebar-link {
	height: 47px;
}
.today-btn,
.small-quick-btn {
	float: right;
	background: #ededed;
	border-radius: 5px;
	padding: 0px 10px;
	margin-right: 1px;
	cursor: pointer;
	font-weight: bold;
	font-size: 12px;
}
.small-quick-btn.success {
	color: var(--rmb-success);
}
.small-quick-btn.error {
	color: #ee0000;
}
.date-holder {
	position: relative;
}
.date-buttons {
	position: absolute;
	top: 0;
	left: 4px;
	align-items: center;
	width: 18px;
	text-align: center;
	line-height: 19px;
}
.date-buttons .small-quick-btn {
	float: none;
	padding: 0px;
	width: 100%;
	display: block;
	margin-bottom: 2px;
}
.datatable-block {
	margin-top: 30px !important;
}
.origins-page .datatable-block {
	margin-top: 0 !important;
}
.srch-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: left;
	justify-content: space-between;
}
.inpt-srch-origin {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	border-radius: 5px;
	border: solid 1px #ddd;
	padding-left: 4px;
	float: right;
	width: 70px;
	height: 19px;
	margin-right: 3px;
	line-height: 18px;
	font-size: 12px;
}
.price-tag {
	font-weight: bold;
	text-align: right;
	display: block;
	/* float: right; */
	width: 90%;
	white-space: nowrap;
}
.applied-filters-block {
	margin-top: 20px;
}
.applied-filters {
	margin: 5px 0;
}
.applied-filters td,
.applied-filters th {
	padding: 0 10px;
	text-align: center;
	border: 1px solid #ddd;
}
.ti-refresh.rotating {
	font-size: 16px;
	animation: rotate 2s linear infinite;
	display: inline-block;
}
.progress-refresh {
	position: relative;
}
.progress-refresh:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: rgba(93, 135, 255, 1);
	width: 100%;
	height: 3px;
	animation: progressbar 1s linear;
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
@keyframes progressbar {
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}
/* CASHIER PAGE */

#cashiers-page-card .card-body .bPokRt:hover,
#cashiers-page-card .card-body .rdt_TableRow:hover,
#cashiers-page-card .form-control:disabled {
	background-color: transparent;
	cursor: initial;
}

#cashiers-page-card .rdt_Pagination {
	display: none;
}

/* SPINNER COMPONENT */

.spinner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #53535382;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.spinner.block {
	position: absolute;
	width: 100%;
	height: 100%;
}
.spinner-limits {
	position: relative;
}
/* MODIFIERS */

.modifiers-list,
#all-modifiers-list {
	max-height: 240px;
	overflow-y: scroll;
}

.remove-icon {
	padding: 2px 5px;
	cursor: pointer;
	border-radius: 11px;
}

.remove-icon i {
	position: relative;
	top: -1px;
}

#all-modifiers-list {
	border: none;
	padding: 0;
	margin-right: 10px;
	height: 240px;
}

#all-modifiers-list option {
	padding-left: 40px;
	height: 40px;
	padding-top: 10px;
}

#add-modifier {
	margin-top: 10px;
	width: 100%;
}

#add-modifier i {
	position: relative;
	top: 2px;
}
.venues-logos-scaled {
	scale: 1.5;
}

/*Sales density*/
.sls-dty {
	display: flex;
	flex-direction: column;
	height: fit-content;
	min-width: calc(100% - 60px);
}
.sls-dty-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 18px;
	position: relative;
}
.sls-dty-header > div:first-child {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}
.sls-dty-header > div:last-child {
	display: flex;
	justify-content: end;
	width: 100%;
	position: absolute;
	right: 0;
}
.sls-dty-header > div:last-child > * {
	width: calc((100% - 60px) / 5);
}
.sls-dty-header > div:last-child > div:not(:last-child) {
	margin-right: 14px;
}
.sls-dty .legend {
	display: flex;
	align-items: center;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	margin-bottom: 24px;
}
.sls-dty .legend span:not(:first-child) {
	margin-left: 32px;
}
.sls-dty .legend span {
	height: 16px;
	width: 16px;
	display: inline-block;
	border-radius: 16px;
	margin-right: 8px;
}
.sls-dty .legend span:first-child {
	background-color: #27af66;
}
.sls-dty .legend span:nth-child(2) {
	background-color: #8b62ff;
}
.sls-dty .legend span:last-child {
	background-color: #62a1ff;
}
.sls-dty h5 {
	margin-top: 15px;
	margin-bottom: 0px;
}
.sls-dty-tb {
	/*parent*/
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-width: calc(100% - 60px);
	position: relative;
}
.sls-dty-tb .loading {
	position: absolute;
	bottom: 0;
	right: 0;
	width: calc(100% - (100% / 15));
	height: calc(100% - (100% / 8));
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.sls-dty-tb-r {
	/*child*/
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 100%;
}
.sls-dty-tb-r.sls-dty-cell:not(.data) {
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}
.sls-dty-cell {
	min-height: calc(100% / 8);
	width: calc(100% / 25);
}
.sls-ocu-cell {
	width: calc(100% / 20);
}
.sls-dty-tb-r.data.bottom {
	border-bottom: 1px solid #ddd;
}
.sls-dty-tb-r:not(.sls-ocu-cell).days,
.sls-dty-tb-r:not(.sls-ocu-cell).corner {
	text-transform: uppercase;
	width: calc(100% / 15);
	display: flex;
	flex-shrink: 0;
}
.sls-ocu-cell.hours{
	text-wrap: nowrap;
	flex-shrink: 0;
}
.sls-dty-tb-r.hours,
.sls-dty-tb-r.days {
	color: #000;
}
.sls-dty-tb-r.weekends {
	font-weight: 500;
}
.sls-dty-tb-r.data,
.density_graph_container .data {
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.sls-dty-tb-r.data {
	padding: 2.75px 2.75px;
	border-right: 1px dotted #dfdddd;
}
.sls-dty-tb-r.data > div {
	height: 100%;
	width: 100%;
	border-radius: 5px;
}

/*Sales density*/
.date-filter input[type='date'],
.date-filter input[type='datetime-local'] {
	border: 1px solid #dde4e9;
	border-radius: 7px;
	max-height: 40px;
	color: #66666c;
	width: 100%;
	padding: 10px 12px;
}
.date-filter input[type='date'].unsetted,
.date-filter input[type='datetime-local'].unsetted {
	color: #bbbbc6;
}
.date-filter {
	position: relative;
	height: 40px;
	max-height: 40px;
	display: flex;
	box-sizing: border-box;
}
.date-filter > div,
.date-filter > p {
	position: absolute;
}
.date-filter > div > p {
	left: 5px;
	bottom: 40px;
	font-weight: 500;
	color: #3a3a3d;
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}
.date-filter > div {
	display: flex;
	width: 100%;
	bottom: 40px;
	align-items: center;
}
.date-filter-to > div {
	justify-content: end;
}
.date-filter > div:not(.month-buttons){
	justify-content: space-between;
}
.date-filter > div > *:not(:last-child) {
	padding: 0 10px;
}
.date-filter > div > div > *:not(:last-child) {
	margin-right: 8px;
}
.date-filter > div > button {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	background-color: transparent;
	border-radius: 3px;
	height: 20px;
	margin-bottom: 5px;
	color: #3a3a3d;
	font-weight: 500;
}
.date-filter > div > div > button {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	border-radius: 3px;
	font-weight: 500;
	background-color: #f2f5f7;
	color: #62a1ff;
}
.date-filter > div > button.active {
	background-color: #f2f5f7;
	color: #62a1ff;
}
.date-filter > div > button:last-child::before,
.date-filter > div > div > button::before {
	font-size: 20px;
}
/* INPUT STYLES */
.input-container p {
	margin-bottom: 0;
}
.input-container {
	border: 1px solid #dde4e9;
	height: 40px;
	max-height: 40px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	border-radius: 7px;
	box-sizing: border-box;
	position: relative;
}
.input-label {
	position: absolute;
	font-weight: 500;
	color: #3a3a3d;
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
}
.input-value-container {
	display: flex;
	padding: 10px 12px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}
.input-value {
	margin: 0;
	font-family: inherit;
	color: #66666c;
	max-width: 70%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}
.input-value.unsetted {
	color: #bbbbc6;
}
.input-value-right {
	display: flex;
	height: 100%;
	align-items: center;
}
.input-value-right > div {
	background-color: #62a1ff;
	color: white;
	height: 24px;
	width: fit-content;
	padding: 0 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 3px;
	margin-right: 8px;
}
.input-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	color: #49494e;
	transition: transform 0.2s ease-out;
}
.input-icon.up {
	transform: rotate(0deg);
}
.input-icon.down {
	transform: rotate(180deg);
}
.input-overlay {
	width: inherit;
	border: none;
	z-index: 5;
	background-color: white;
	border-radius: 7px;
	position: absolute;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	left: 0;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	padding: 0;
	box-shadow: 2px 2px 15px 0px #cecece;
}
.input-overlay.focused {
	padding-top: 14px;
	max-height: 250px;
	border: 1px solid #dde4e9;
}
.input-overlay hr {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	border: 0.7px solid #999;
	width: 100%;
	margin: 6px 0 12px 0;
}
.input-overlay input[type='text'] {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	background-color: #f2f5f7;
	border-radius: 4px;
	width: calc(100% - 28px);
	display: flex;
	flex-shrink: 0;
	min-height: 40px;
	padding: 0px 10px;
	margin: 0 14px 12px 14px;
	font-size: 16px;
	color: #66666c;
}
.input-overlay input::placeholder {
	color: #bbbbc6;
}
.input-overlay input:focus {
	outline: 2px solid #62a1ff;
}
.input-tags-list {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 0;
	padding: 0 14px;
	list-style: none;
	display: flex;
	overflow-x: scroll;
	overflow-y: hidden;
	height: fit-content;
	flex-shrink: 0;
	width: calc(100% - 14px);
}
.input-tags-list::-webkit-scrollbar {
	display: none;
}
.input-tags-list > li {
	margin: 0 5px 5px 0;
	background-color: #f2f5f7;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 4px 8px;
	box-sizing: border-box;
	font-size: 14px;
	color: #66666c;
	height: 40px;
	min-width: 64px;
	flex-shrink: 0;
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	border: 1px solid #f2f5f7;
}
.input-tags-list > li > p {
	max-width: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.input-tags-list > li:last-child {
	margin: 0 0 5px 0;
}
.input-tags-list > li.active {
	color: #62a1ff;
	border: 1px solid #62a1ff;
}
.input-tags-list > li > img,
.input-tags-list > li > span {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #66666c;
	margin-left: 6px;
	scale: 1.3;
}
.input-tags-list > li.active > img,
.input-tags-list > li.active > span {
	color: #62a1ff;
}
.input-options-container {
	padding: 5px 14px 14px 14px;
	margin-top: 0;
	margin-bottom: 5px;
	height: 100%;
	overflow: scroll;
	cursor: pointer;
}
.input-options-container::-webkit-scrollbar {
	display: none;
}
.input-options-container li {
	cursor: pointer;
	padding-left: 3px;
	padding-right: 3px;
}
.input-options-container li:hover {
	background-color: #f2f5f7;
}
.input-option-container {
	max-height: 34px;
	height: 34px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	color: #66666c;
}
.input-option-container > p {
	max-width: 75%;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.input-option-container.checked {
	color: #62a1ff;
}
/* INPUT STYLES */
.clear-filters, .apply-filters,
.update-data, .download-xls {
	-webkit-appearance: none;
	appearance: none;
	font-size: 14px;
	font-weight: 400;
	padding: 10px 28px;
	border-radius: 7px;
	max-height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}
.update-data,
.clear-filters {
	color: #62a1ff;
	background-color: white;
	margin-right: 15px;
	border: 1px solid #62a1ff;
}
.clear-filters:hover,
.update-data:hover {
	background-color: #62a1ff23;
}
.clear-filters > span {
	margin-left: 8px;
}
.apply-filters,
.download-xls {
	color: white;
	background-color: #62a1ff;
}
.apply-filters.disabled {
	background-color: #eee;
	pointer-events: none;
	color: #bbb;
}
.apply-filters:hover,
.download-xls:hover{
	background-color: #77acfb;
}
.apply-filters:active,
.download-xls:active {
	background-color: #1975ff;
	color: white;
}
.download-xls:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}
/* SWITCH STYLES */
.switch-container {
	display: flex;
	align-items: center;
	height: 38px;
}
.switch-container.disable {
	pointer-events: none;
}
.switch-container > .label {
	margin-bottom: 0;
	text-wrap: nowrap;
}
.switch {
	height: 20px;
	border-radius: 10px;
	width: 38px;
	margin-left: 12px;
	position: relative;
	cursor: pointer;
}
.switch.on {
	background-color: #62a1ff;
	justify-content: end;
	transition: background-color 0.15s ease-out;
}
.switch.off {
	transition: background-color 0.15s ease-out;
	background-color: #ccc;
	justify-content: start;
}
.switch > span {
	border-radius: 100%;
	height: 15px;
	width: 15px;
	background-color: white;
	border: none;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
}
.switch.on > span {
	left: calc(100% - 18px);
	transition: left 0.3s ease-out;
}
.switch.off > span {
	left: 3px;
	transition: left 0.3s ease-out;
}
/* SWITCH STYLES */
.display-none {
	display: none;
}
.header-datatable {
	width: 100%;
}
.parts-container {
	width: 100%;
	display: flex;justify-content: flex-end;
	& > *:not(:last-child) {
		margin-right: 12px;
	}
}
.header-datatable > div:first-child {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header-datatable .input-container {
	width: calc(100% / 4 - 12px);
}
.metrics-select-container {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	margin-top: 12px;
}
.metrics-select-container > p {
	position: relative;
	bottom: 3.5px;
}
.metrics-select-container > #slct-metrics {
	margin-left: 12px;
	width: calc((100% - 60px) / 5);
}
.expandable-component ul {
	background-color: #fffae2;
	margin-bottom: 0;
}
.expandable-component {
	width: inherit;
}
.expandable-component ul li {
	display: flex;
	height: 37px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 0 48px;
}
.origins-page .expandable-component li {
	cursor: pointer;
}
.expandable-component ul li:not(:last-child) {
	border-bottom: 1px solid #ccc;
}
.expandable-component ul li span {
	display: flex;
}
.origins-page .expandable-component ul li span:first-child {
	/*ID COL*/
	max-width: 75px;
	width: 75px;
	flex-shrink: 0;
}
.origins-page .expandable-component ul li span:nth-child(2) {
	/*NAME COL*/
	max-width: 100%;
	width: 100%;
}
.origins-page .expandable-component ul li span:nth-child(3) {
	/*PARENT COL*/
	max-width: 75px;
	width: 75px;
	flex-shrink: 0;
}
.origins-page .expandable-component ul li span:nth-child(4) {
	/*ORDER COL*/
	max-width: 75px;
	width: 75px;
	flex-shrink: 0;
}
.origins-page .expandable-component ul li span:nth-child(5) {
	/*VENUE COL*/
	max-width: 75px;
	width: 75px;
	flex-shrink: 0;
}
.origins-page .expandable-component ul li span:nth-child(6) {
	/*CREATED AT COL*/
	max-width: 140px;
	width: 140px;
	flex-shrink: 0;
}
.origins-page .expandable-component ul li span:nth-child(7) {
	/*UPDATED AT COL*/
	max-width: 140px;
	width: 140px;
	flex-shrink: 0;
}
.expandable-component.weekData ul {
	background-color: #efefef;
	font-size: 13px;
}
.expandable-component.weekData li {
	padding: 0px;
}
.expandable-component.weekData span:first-child {
	justify-content: start;
	width: 100%;
	min-width: 100px;
}
.expandable-component.weekData span:nth-child(2),
.expandable-component.weekData span:nth-child(3),
.expandable-component.weekData span:nth-child(4),
.expandable-component.weekData span:nth-child(5) {
	width: 90px;
	min-width: 90px;
}
.expandable-component.weekData span:nth-child(6),
.expandable-component.weekData span:nth-child(7) {
	width: 150px;
	min-width: 150px;
}
.expandable-component.weekData span:nth-child(4),
.expandable-component.weekData span:nth-child(5),
.expandable-component.weekData span:nth-child(6),
.expandable-component.weekData span:nth-child(7) {
	justify-content: end;
}
.origin_form > div:nth-of-type(3) > :first-child{
	width: 100%;
}
.origin_form.parent *{
	pointer-events: none;
}

/* Density graph container */
.density_graph_container {
	display: flex;
	width: 100%;
	height: auto;
	max-width: 100%;
	flex-direction: column;
	overflow-x: scroll;
	margin-top: 24px;
	border-top: 1px solid #ccc;
}
.density_graph_cols_container {
	display: flex;
}
.density_graph_cells_container {
	height: 50px;
	width: 100%;	
}
.density_graph_cells_container.corner {
	background-color: transparent;
	border: none;
	padding: 8px;
}
.density_graph_cells_container.corner,
.density_graph_cells_container.row_title,
.density_graph_cells_container.col_title
 {
	background-color: white;
	color: black;
	text-wrap: nowrap;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.density_graph_cells_container.col_title {
	border-left: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}
.density_graph_cells_container.row_title,
.density_graph_cells_container.corner,
.density_graph_cells_container.data {
	border-bottom: 1px solid #ccc;
}
.density_graph_cells_container.col_1 {
	border-left: 1px solid #ccc;
}
.density_graph_cells_container.data {
	padding: 4px;
}
.density_graph_cells_container.data > div {
	height: 100%;
	width: 100%;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.density_graph_cells_container.data > div > p {
	margin: 0;
	color: white;
	font-weight: bolder;
}
.density_graph_cells_container.data .tooltiptext {
	bottom: 100%;
}
/* cell tooltip */
.tooltiptext p {
	margin: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	gap: 2px;
}
.data:hover .tooltiptext,
.tooltip_container:hover .tooltiptext {
	visibility: visible!important;
	opacity: 1!important;
}
.tooltiptext {
	visibility: hidden;
	width: auto;
	flex-shrink: 0;
	background-color: #333;
	color: #fff;
	text-wrap: nowrap;
	text-align: center;
	padding: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	gap: 10px;
	z-index: 10;
	height: auto;
	bottom: 125%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	transition: opacity 0.3s;
}
.tooltiptext::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #333 transparent transparent transparent;
}


.zones_graphic{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
	position: relative;

	.tooltiptext {
		left: 0;
		bottom: 50%;
		transform: translate(-100%, 50%);
		&::after {
			left: 100%;
			top: 50%;
			transform: translate(50%, 50%);
			rotate: -90deg;
		}
		.separator:last-child {display: none;}
	}
}
.zones_graphic span {
	border: none;
	margin: none;
	height: 75%;
}
.zones_graphic .z_a {
	background-color: #f1b736;
}
.zones_graphic .z_a2 {
	background-color: #27af66;
}
.zones_graphic .z_b {
	background-color: #62a1ff;
}
.zones_graphic .z_c {
	background-color: #8b62ff;
}
.zones_graphic .z_v {
	background-color: #ff6b62;
}
.zones_graphic .z_sin {
	background-color: #d1d1d1;
}
/*
	EVENTS PER WEEK STYLES --------------------------------------------------------
*/
.week_content {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	overflow: scroll;
}
.week_content::-webkit-scrollbar {
	display: none;
}
.day_headers, .events_container {
	display: flex;
}
.day_headers {
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 2;
	padding: 0px 4px;
	background-color: white;
}
.day_headers::after {
	content: "";
	position: absolute;
	bottom: -8px;
	height: 8px;
	width: 100%;
	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);;
}
.events_container {
	padding: 0px 4px;
	padding-top: 8px;
	padding-bottom: 8px;
	align-items: start;
}

.day_headers .day {
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	height: 40px;
	border-radius: 6px;
	width: 100%;
	border: 1px solid transparent;
	color: white;
	background-color: #666;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 4px;
	min-width: 140.19px;
}
.day_headers .day, .events_container .day_column   {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
} 
.events_container .day_column {
	flex-direction: column;
	margin: 0 4px;
	width: 100%;
}
.events_container {
	max-height: 500px;
}

.event_cell {
	border-radius: 4px;
	margin-bottom: 8px;
	width: 100%;
	padding: 8px;
	background-color: #fff;
	box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
.event_cell div:first-child {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ccc;
	margin-bottom: 12px;
	cursor: pointer;
	padding-bottom: 10px;
}
.event_cell div:first-child p, 
.events_per_week_container .controls .info_week,
.info_week {
	font-weight: 500;
	color: #333;
	font-size: 14px;
	margin-right: 8px;
	margin-bottom: 0;
	flex: 1;
}
.events_per_week_container .controls .info_week,
.info_week {
	font-size: 16px;
	display: inline-block;
}
.event_cell img{
	max-width: 35px;
	object-fit:contain;
	flex-shrink: 1;
	height: auto;
}
.event_cell .switch-container {
	justify-content: space-between;
}
.events_per_week_container .controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
	position: relative;
}
.events_per_week_container .controls > div:last-child {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	position: absolute;
	right: 0;
}
.icon_transparent_button {
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
	border: none;
	height: 50px;
	width: 50px;
	font-size: 24px;
	transition: color .2s ease;
}
.icon_transparent_button.disable {
	color: #aaa;
	pointer-events: none;
}


.event_cell {
	outline: 2px solid transparent;
}
.event_cell:hover {
	transition: outline .2s ease;
	outline: 2px solid #aaa;
}
.event_cell.no_data {
	text-align: center;
	justify-content: center;
	align-items: center;
}
.event_cell.no_data p {
	margin-bottom: 0;
}
/*
	EDIT PAGE --------------------------------------------------------
*/
.edit_page .header{
	justify-content: space-between;
	align-items: center;
	display: flex;
}
.edit_page .header > button {
	width: auto;
	font-size: 17px;
	color: #5a6a85;
	display: flex;
	align-items: center;
}
.edit_page .header > button span{
	margin-right: 12px;
	font-size: 24px;
}
.edit_page form {
	margin-top: 24px;
}
.event_form > div:first-child {
	display: flex;
	align-items: start;
	justify-content: space-between;
}
.event_form > div:first-child > * {
	width: calc(50% - 12px);
}
.event_form .left_side > *:not(:last-child, .switch_wrapper, .update_info) {
	margin-bottom: 16px;
}
.edit_page .label {
	margin-bottom: 4px;
	font-weight: 500;
	color: #3a3a3d;
}
.switch_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}
.switch_wrapper .switch-container{
	width: 150px;
	justify-content: space-between;
	flex-shrink: 0;
}
.update_info {
	margin-top: 24px;
	margin-bottom: 0;
}
.event_form .occupation_graphic {
	width: 100%;
	height: 40px;
	background-color: #f2f5f7;
	margin: 0 8px;
	border-radius: 8px;
}
.event_form .input_group {
	display: flex;
	justify-content: space-between;
}
.event_form .input_group > div {
	width: 100%;
}
.event_form .input_group:not(:first-child) > div:first-child {
	margin-right: 12px;
}
.event_form .react-datepicker-wrapper {
	width: 100%;
}
.event_form .input_group:nth-child(3) {
	margin-bottom: 36px!important;
}
.occupation_graphic span{
	height: 100%;
	display: inline-block;
	border-radius: 8px;
}
.switch_wrapper > span:last-child {
	text-wrap: nowrap;
	min-width: 115px;
	max-width: 115px;
	text-align: center;
}
.event_form .z_v .occupation_graphic span {
	background-color: #ff6b62;
}
.event_form .z_a .occupation_graphic span {
	background-color: #f1b736;
}
.event_form .z_b .occupation_graphic span {
	background-color: #62a1ff;
}
.event_form .z_c .occupation_graphic span {
	background-color: #8b62ff;
}
/*
	SCHEDULE --------------------------------------------------------
*/
#schedule_list {
	overflow-y: visible;
}
#schedule_list::-webkit-scrollbar {
	display: none;
}
#schedule_list li {
	position: relative;
	display: flex;
	width: 100%;
	height: 80px;
	border-radius: 8px;
	bottom: 0;
	right: 0;
	background-color: #f2f5f7;
	border: 1px solid #dfe5ef;
	animation: fade_in .2s ease;
	z-index: 1;
}
#schedule_list li:not(:last-child) {
	margin-bottom: 8px;
}
#schedule_list li .controls_left,
#schedule_list li .controls_right {
	width: 50px;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}
#schedule_list li .controls_left{
	border-right: 1px solid #dfe5ef;
}
#schedule_list li .controls_right{
	border-left: 1px solid #dfe5ef;
}
#schedule_list li .controls_left > * ,
#schedule_list li .controls_right > * {
	height: 50%;
	border: none;
	background-color: transparent;
}
#schedule_list li .controls_left > *:first-child ,
#schedule_list li .controls_right > *:first-child {
	border-bottom: 1px solid #dfe5ef;
}
#schedule_list li .palo_info {
	width: 100%;
}
#schedule_list button {
	-webkit-appearance: none;
	appearance: none;
}
#schedule_list li .palo_info select {
	width: 100%;
	border: none;
	background-color: transparent;
	height: 50%;
	padding: 0 8px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
#schedule_list li .palo_info select:not(:last-child) {
	border-bottom: 1px solid #dfe5ef;
}
#schedule_list .add_palo_li {
	height: 40px;
}
#schedule_list .add_palo {
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 8px;
	font-size: 20px;
	color: #1975ff;
	border: 1px solid #1975ff;
	background-color: #77acfb2c;
	transition: background-color .2s ease;
}
#schedule_list .add_palo:hover {
	background-color: #77acfb46;
}
#schedule_list button {
	font-size: 18px;
	transition: color .2s ease;
}
#schedule_list .no_fixed, #schedule_list .disable{
	color: #aaa;
}
#schedule_list .disable {
	pointer-events: none;
}
#schedule_list .delete {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 2px;
	color: crimson!important;
}
#schedule_list li.above {
	bottom: 88px;
	z-index: 2;
	transition: bottom 0.2s ease-out;
}
#schedule_list li.below {
	bottom: -88px;
	z-index: 2;
	transition: bottom 0.2s ease-out;
}
#schedule_list li.superior {
	bottom: -88px;
	z-index: 1;
	transition: bottom 0.2s ease-out;
}
#schedule_list li.inferior {
	bottom: 88px;
	z-index: 1;
	transition: bottom 0.2s ease-out;
}
#schedule_list li.inferior_add {
	bottom: -88px;
	z-index: 1;
	transition: bottom 0.2s ease-out;
}

#schedule_list li.deleted {
	right: calc(100% + 50px);
	opacity: 0;
	transition: right 0.2s ease-out, opacity .1s ease;
}
.event_form select:not(.ar, .pa),
.pricing_rule_form select {
	height: 39px;
	width: 100%;
	display: block;
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5a6a85;
    border: 1px solid #dfe5ef;
    appearance: none;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pricing_rule_form {
	.rmb-btn-secondary {
		background-color: transparent;
		color: var(--rmb-secondary);
	}
}
@keyframes fade_in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.tracking_page_header .page_title, .tracking_page_header .page_title span {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}
.tracking_page_header .page_title span {
	text-transform: none;
	font-weight: 500;
	border-left: 2px solid #aaa;
	height: 30px;
	margin-left: 10px;
	padding-left: 10px;
}
.tracking_page_header  > div:last-child {
	display: flex;
	width: 100%;
}
.tracking_page_header {
	margin: 1rem 0;
}
.trackings_container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
}
.trackings_container .loader_container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(1px);
	display: flex;
	justify-content: center;
	align-items: center;
}
.trackings_container .loader_container .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #999;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
.trackings_container .trackings {
	width: calc((100% / 4) - 24px);
	max-width: 250px;
	min-width: 200px;
	padding: 0 12px 12px 0;
	border-radius: 8px;
	box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
	margin: 12px 12px 12px 12px;
	padding: 12px;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	height: 100px;
}
.trackings_container .trackings p, .trackings_container .trackings span{
	margin-bottom: 0;
}
.trackings_container .trackings .comparation {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	color: #222;
	font-weight: 500;
	margin-bottom: 0;
}
.trackings_container .trackings .ti {
	font-size: 20px;
}
.trackings_container .trackings .title {
	color: #666;
	font-weight: 500;
	margin-bottom: 6px;
	display: flex;
	align-items: start;
}
	
.trackings_container .trackings .title .ti {
	margin-right: 8px;
}
.trackings_container .trackings .comparation .ti {
	margin-left: 3px;
}
.trackings_container .trackings .comparation > span:last-child {
	display: flex;
	justify-content: flex-end;
}
.trackings_container .trackings .comparation .percentage {
	font-size: 12px;
	color: var(--rmb-default);
	position: relative;
	bottom: 2px;
}
.trackings_container .trackings .up {
	color: var(--rmb-success)
}
.trackings_container .trackings .down {
	color: var(--rmb-danger)
}
.trackings_container .trackings .equal {
	color: var(--rmb-default)
}
.date_controller {
	display: flex;
	align-items: center;
	height: 40px;
}
/* ===========================================================================================
FILTERS STYLES
=========================================================================================== */
.page-filters {
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	margin: 0 0 24px 0;
}
.page_title {
	font-size: 16px;
	color: #555;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0;
}
.page_title > * {
	text-transform: uppercase;
}
.page-filters * {
	transition: opacity .2s ease, color .2s ease, backgroun-color .2s ease;
}
.page-filters .disable {
	pointer-events: none;
}
.page-filters button.disable {
	opacity: 0.5;
}
.filters_row {
	width: 100%;
	display: flex;
	align-items: center;
	height: 40px;
	justify-content: space-between;
	margin-bottom: 12px;
}
.filters_row:has( .input-label, .date-filter) {
	margin-top: 40px;
}
.filters_row > .input-container, .filters_row > .date-filter {
	width: 100px;
	min-width: 100px;
}
.filters_row > *, .extra_div > *{
	flex-grow: 1;
	flex-shrink: 0;
}
.extra_div {
	display: flex;
	align-items: center;
	height: 100%;
}
.extra_div:first-child {
	justify-content: flex-start;
}
.extra_div:first-child > *:not(:last-child) {
	margin-right: 12px;
}
.extra_div:not(:first-child, :last-child){
	justify-content: space-between;
}
.extra_div:last-child {
	justify-content: flex-end;
}
.extra_div:last-child > *:not(:last-child) {
	margin-right: 12px;
}
.filters_row > *:not(:last-child, .extra_div) {
	margin-right: 12px;
}
.filters_row > button, .extra_div > button {
	width: fit-content!important;
	flex-grow: 0;
	min-height: 40px;
}
.extra_div > * {
	flex-grow: 0!important;
	flex-shrink: 0;
}
.page-filters .switch-container {
	flex-grow: 0!important;
}
/* cutom filters*/
#slct-omit {
	width: calc(100% / 2);
	max-width: 200px;
}
.eventsPage .filters_row {
	justify-content: start;
}
.eventsPage #slct-tablao, 
.eventsPage #slct-shift {
	max-width: calc(100% / 5);
}
.trackingsPage #slct-tablao {
	max-width: calc(100% / 4 - 12px);
}
/* ===========================================================================================
=========================================================================================== */
.pricing_rule_form > div:first-child {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.pricing_rule_form > div:first-child .left_side, 
.pricing_rule_form > div:first-child .right_side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
} 
.pricing_rule_form > div:first-child .left_side {
	flex-shrink: 0;
	margin-right: 24px;
	width: 50%;
	max-width: 420px;
}
.pricing_rule_form > div:first-child .right_side,
.pricing_rule_form > div:first-child .right_side > div{
	width: 100%;
}
.pricing_rule_form .input_group {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 12px;
}
.pricing_rule_form .input_group > * {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.pricing_rule_form .input_group > *:not(:last-child) {
	margin-right: 12px;
}
.rules_container {
	display: flex;
	min-height:  57px;
    width: 100%;
    max-width: 100%;
	flex-wrap: wrap;
    padding: 8px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5a6a85;
    background-color: transparent;
    background-clip: padding-box;
    border: var(--bs-border-width) solid #dfe5ef;
    appearance: none;
    border-radius: 7px;
    box-shadow: unset;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pricing_rule_form .add {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	background-color: #77acfb4f;
	width: 39px;
	border-radius: 4px;
	color: #1975ff;
	min-height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border-radius: 7px;
}
.pricing_rule_form .clear {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	background-color: #fb77774f;
	width: 39px;
	border-radius: 4px;
	color: #ff1919;
	min-height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border-radius: 7px;
}
.pricing_rule_form .clear span {
	rotate: 45deg;
}
.pricing_rule_form .add:not(:first-child),
.pricing_rule_form .rule_controls > :not(:first-child) {
	margin-left: 4px;
}
.rule_controls {
	margin: 4px 0 12px 0;
	display: flex;
}
.rule_controls >:not(button) {
	width: 100%;
	max-width: 200px;
}
.rule_value {
	background-color: #dfe5ef99;
	padding: 4px 8px;
	border-radius: 2px;
	margin: 0 4px 4px 0;
	position: relative;
	top: 1px;
	max-height: 24px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: scale .2s ease, opacity .2s ease;
}
.rule_value:hover {
	opacity: 0.35;
}
.input_group > div > p:not(.label) {
	margin-bottom: 4px;
	font-size: 12px;
}